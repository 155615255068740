.BigButton {
  padding: 1rem;
  width: 100%;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  border: 0;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
    background: #555;
    transition: 0.1s;
  }
}
@keyframes ClientLoaderAnimation__float {
  0% {
    
  }
  50% {
    transform: translate(0px, -20px);
  }
}

.ClientLoaderAnimation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  
  &__video {
    width: 200px;
    animation: ClientLoaderAnimation__float 2s ease infinite;
  }
  &__message {
    font-size: 1.5rem;
  }
}
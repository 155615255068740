.header {
  &-title {
    margin: 0.5rem;
  }
  &-component {
    position: relative;
    right: -15vw;
    padding: 1rem;
    font-size: 0.7rem;
  }

  &-assets {
    color: #4c8bf5;
    display: flex;
    height: 3px;
    align-items: center;
    margin: 0.5rem 0.5rem 0.5rem 0;
  }

  &-sub__component {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    justify-content: space-between;
  }

  &-upper-right {
    display: flex;
    align-items: center;
  }

  &-search {
    height: 1rem;
  }

  &-alert {
    height: 1rem;
  }
}

.search-component {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: none;
  border: 1px grey solid;
  border-radius: 0.5rem;
  padding: 0.5rem;
  height: 1rem;
  width: 9rem;
}

.arrow {
  height: 3px;
}

p {
  padding: none;
  margin: none;
}

.divider {
  display: flex;
  align-items: end;
}

#login-screen {

  background: url(./Forestry.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  background-color: #282c34;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

#login-form {
  position: absolute;
  right: 120px;
  margin: auto;
  margin-left: 20px;
}

.tagline {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

#login-form>* {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#login-form>input {
  background: #ffffff;
  padding: 20px;
  font-size: 1.2em;
  border-radius: 20px;
  border: none;
  width: 100%;
  margin-top: 20px;
}

#login-form>a {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  color: #4c8bf5;
}

#login-form-logo {
  width: 235px;
  height: 137px;
  margin: auto;
}

input[type='checkbox'] {
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 10px;
  vertical-align: middle;
}

#login-remember {
  margin-top: 10px;
  flex-direction: row;
}

#login-remember>span {
  margin-left: 17px;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  vertical-align: middle;
}

#login-form-submit {
  margin-top: 42px;
  width: 100%;
  height: 75px;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;

  background: #4c8bf5;
  border: 0;
  border-radius: 20px;
}

.third-party-login,
.reset-password {
  text-decoration: none;
  color: white;
  padding: 1rem;
}

@media (max-width: 320px) {

  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  #login-form {
    right: 20px;
    top: 40px;
  }

  #login-form-logo {
    width: 200px;
    height: 102px;
  }

  #login-remember>span,
  .tagline {
    font-size: 16px;
    line-height: 18px;
  }
}

@media (max-width: 480px) {

  /* smartphones, Android phones, landscape iPhone */
  #login-form {
    right: 20px;
    top: 40px;
  }

  #login-form-logo {
    width: 200px;
    height: 102px;
  }

  #login-remember>span,
  .tagline {
    font-size: 16px;
    line-height: 18px;
  }
}

@media (min-width: 600px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}
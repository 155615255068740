.AppContainerFallback {
  display: flex;
  width: 100vw;
  height: 100vh;
  background: #f9f8f7;
  padding: 2rem;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px #888;

  &__dialog {
    padding: 2rem;
    background: #282c34;
    color: white;
    flex: 2;
    border-radius: 12px;
    height: 80vh;
    margin: auto 0;

    &__text {
      width: 60%;
    }

    &__action {
      display: flex;

      &__button {
        width: 10rem;
      }
    }

    // Symboticware logo
    &__logo {
      display: flex;
      justify-content: center;
    }
  }

  &__error {
    flex: 1;
    padding: 2rem;
  }
}
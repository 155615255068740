.home-component {
    position: relative;
    right: -15vw;
    top: -104rem;
    width: 85%;
    padding: 1rem;

    &__botton {
        display: flex;
        border: 1px gray solid; 
        border-radius: 0.5rem; 
        margin: 0.5rem;
        width: 33rem;
    }

    &__middle {
        border: 1px gray solid; 
        width: 33rem;
    }

    &__direction {
        display: flex;
        flex-direction: column;
        align-items: left;
        border-left: 1px gray solid; 
        padding: 1rem; 
    }
}

.home-components {
    display: flex; 
}

.home {

    &__truck-img {
        height: 10rem; 
    }

    &-component__top {
    display: flex; 
    font-size: 0.5rem; 
    padding: 0.25rem; 
    border: 1px grey solid;
    border-radius: 0.5rem; 
    margin: 0.5rem;
    }

    &-component__middle {
        display: flex; 
        border-radius: 0.5rem; 
        margin: 0.5rem; 
    }

    &-sub__division {
        font-size: 0.75rem; 
        padding: 0.5rem;
        border-left: 1px gray solid;
    }

    &-location {
        padding: 1rem; 
    }
}

.img-title {
    font-size: 0.7rem; 
    padding: 0.5rem 0.5rem 0 0.5rem; 
}

.direction {
    height: 8rem; 

}

.written {
    display: flex;
    font-size: 0.7rem;
}

.gps-location {
    height: 10rem; 
}

.GPS {
    font-size: 0.5rem;
    margin: 0;
    padding: 0;

    &-img {
        height: 7rem; 
    }
}

.location-title {
    font-size: 0.7rem;
    display:flex;
    justify-content: left;
}
